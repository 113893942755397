import * as React from 'react';
import classNames from 'classnames';
import MediaContainerContent from '../../MediaContainer/viewer/MediaContainerContent';
import mediaContainerStyles from '../../MediaContainer/viewer/styles/MediaContainer.scss';
import { HoverBoxCompProps } from '../HoverBox.types';
import { useIsomorphicLayoutEffect } from '../../../../providers/useIsomorphicLayoutEffect';
import styles from './styles/HoverBox.scss';

const HoverBox: React.FC<HoverBoxCompProps> = props => {
  const {
    id,
    children,
    mode = 'default',
    modeDidUpdate,
    onClick,
    onDblClick: onDoubleClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  useIsomorphicLayoutEffect(() => {
    modeDidUpdate?.(props.mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode]);

  return (
    <div
      id={id}
      data-mode={mode}
      className={classNames(mediaContainerStyles.mediaContainer)}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.container}>
        <MediaContainerContent {...props}>{children}</MediaContainerContent>
      </div>
    </div>
  );
};

export default HoverBox;
