import * as React from 'react';
import FillLayers from '../../FillLayers/viewer/FillLayers';
import { PageBackgroundProps } from '../PageBackground.types';
import styles from './style/PageBackground.scss';

const PageBackground: React.FC<PageBackgroundProps> = props => {
  const {
    id,
    fillLayers,
    isMediaPositionOverride,
    mediaHeightOverrideType,
    getPlaceholder,
  } = props;

  return (
    <div
      id={id}
      data-media-height-override-type={mediaHeightOverrideType}
      // todo: remove the data-media-position-override when TB includes wix-custom-elements that supports the updated attribute
      data-media-position-override={isMediaPositionOverride}
      className={styles.pageBackground}
    >
      <FillLayers
        {...fillLayers}
        shouldRenderUnderlay={true}
        getPlaceholder={getPlaceholder}
      />
    </div>
  );
};

export default PageBackground;
